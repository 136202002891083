html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Image preview in company and websites results views. */
.imagePreviewMask {
  background: none;
}

/* Not display some operations in image preview expanded in company and websites results views. */
.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}

/* Custom fonts. */
@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Medium.ttf");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

/* If not below part, button text is not centered vertically. */
.ant-btn {
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* Login input */
#loginEmailInput.ant-input,
#loginPasswordInput.ant-input,
#signUpEmailInput.ant-input,
#signUpPasswordInput.ant-input,
#signUpConfirmPasswordInput.ant-input,
#forgotPasswordInput.ant-input {
  font-size: 20px;
}

/* Filters */
#companyFiltersCard,
#websiteFiltersCard {
  border-radius: 10px;
}

#companyFiltersCard .ant-card-body {
  padding: 0;
}
#websiteFiltersCard .ant-card-body {
  padding: 0;
}

/* Modal popups */
.ant-modal-content,
.ant-modal-header {
  border-radius: 10px;
}

/* Filter item headers */
.ant-collapse-header {
  padding: 2px 10px !important;
}
