
.search-input {
    border-radius: 10px;
    background-color: white !important;
    min-height: 20px !important;
}

/* If the multilist field is shirt (should not scroll) */
.short-field-multilist{
    overflow-y: visible;
    max-height: fit-content !important;
}